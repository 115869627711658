export default ({
  namespaced: true,
  state: () => ({
    paymentAtlasTableData: {
      colsWidth: ['14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: 'Name of Bank' },
        { ttl: 'Country of Bank' },
        { ttl: 'Type of Account' },
        { ttl: 'Transaction History' },
        { ttl: 'Balance' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
    }
  }),
  actions: {
    getPaymentAtlases ({ commit }, payload = { page: 1 }) {
      axios.get(process.env.VUE_APP_BACKEND_API_URL +'admin/payment-atlas?page='+ payload.page +'&provider=true')
        .then(({ data }) => {
          commit('setPaymentAtlases', { data: data })
        })
        .catch(err => console.dir(err))
    },
  },
  mutations: {
    setPaymentAtlases (state, payload) {
      state.paymentAtlasTableData.paginationData = payload.data
      state.paymentAtlasTableData.body = []
      payload.data.data.forEach(el => {
        const name = !el.provider_id && !el.is_legal_company
          ? el.bank_name
          : {
              type: 'info',
              html: el.bank_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        state.paymentAtlasTableData.body.push([
          name,
          el.country ? el.country.name : '',
          el.account_type,
          el.transaction_history,
          +el.balance,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
    }
  }
})
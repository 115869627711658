<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="sbttl">
        Add members
      </div>
    </div>
    <div class="modal__body">
      <div>
        <div class="row">
          <input v-model="nameOrEmail" class="form-control" type="text" placeholder="Enter name or email">
        </div>
        <div class="row">
          <overlay-scrollbars style="height: 20rem;">
            <div class="participants-block">
              <div v-if="error.length > 0" class="invalid-feedback">{{ error }}</div>
              <div class="participant-item" v-for="participant in filteredParticipants" :key="participant.id">
                <div class="avatar-img">
                  <img v-if="participant.logo" :src="thumbsUrl + participant.logo" />
                  <img v-else src="/img/default-avatar.png" /> 
                </div>
                <span v-if="participant.company_full_name">{{ participant.company_full_name }}</span>
                <span v-else>{{ participant.email }}</span>
                <input v-model="selectedIds" :value="participant.id" type="checkbox" class="participant-checkbox" name="participant_checkbox">
              </div>
            </div>
          </overlay-scrollbars>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Create</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      error: '',
      selectedIds: [],
      nameOrEmail: '',
      filteredParticipants: [],
      thumbsUrl: process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/thumbs/',
    };
  },
  methods: {
    ...mapMutations({
      createChatRoom: 'CREATE_CHAT_ROOM',
    }),
    async submit() {
      if (this.selectedIds.length === 0) {
        this.error = 'Please, choose participant'
        return
      }
      this.error = ''
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'chat/create-room', {
          participants_ids: this.selectedIds,
        })
      .then(function (response) { 
        if (response.data.success) {
          that.createChatRoom(response.data.room)
          that.$noty.success('You successfully create chat')
        }
        that.close()
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  },
  watch: {
    nameOrEmail (nameOrEmail) {
      console.log(this.options.participants)
      if (nameOrEmail.length > 0) {
        this.filteredParticipants = []
        this.options.participants.forEach(participant => {
          let include = false
          if (participant.company_full_name && participant.company_full_name.toLowerCase().includes(nameOrEmail.toLowerCase())) {
            include = true
          }
          if (participant.email && participant.email.toLowerCase().includes(nameOrEmail.toLowerCase())) {
            include = true
          }
          if (include) {
            this.filteredParticipants.push(participant)
          }
        }) 
      } else {
        this.filteredParticipants = this.options.participants
      }
    }
  },
  created () {
    this.filteredParticipants = this.options.participants
  }
};
</script>

<style lang="scss" scoped>

.modal__wrapper {
  max-height: 40rem;
  width: 30rem;
}

.modal__close {
  .btn::before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    color: white;
    font-size: 2.25rem;
    font-weight: 300;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: 0.75rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}

.modal__footer {
  .btn {
    font-size: 18px;
    padding: 1rem 3rem;
    margin: 0.25rem 1rem;
    background: var(--gr-2);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
  }
}

.participant-item {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  position: relative;
}

.avatar-img {
  display: inline;
  margin-right: 20px;
}

.avatar-img img {
  width: 30px;
  height: 30px;
  display: inline;
  border-radius: 10px;
}

.participant-checkbox {
  border-radius: 5px;
  position: absolute;
  right: 10px;
}

.participants-block {
  width: 25rem;
}

.form-control {
  background-color: #ffffff;
  color: var(--c_black);
}
</style>

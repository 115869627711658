<template>
  <overlay-scrollbars class="page page-personal">
    <div class="box box_rate">
      <div class="box-inside">
        <div class="content">
          <div class="title">KYB Compliance Rate</div>
          <div class="subtitle">Minimum 60% need to get first option of service</div>
          <div class="scale-box">
            <div class="scale">
              <div class="progress" :style="{ 'width': `${rate}%` }">
                <div class="note">{{ rate }}%</div>
              </div>
              <div v-if="kybRateComment" class="kyb-rate-comment">{{ kybRateComment }}</div>
            </div>
          </div>
        </div>
        <img src="/img/rate-img.png" class="img">
      </div>
    </div>
    <div class="box box_my-profile">
        <div v-if="!editMyProfileState" class="btn btn-edit" @click="editMyProfile">
          <Pen class="img"/>
        </div>
        <div v-if="editMyProfileState" class="btn btn-save" @click="saveMyProfile">
          <Save class="img"/>
        </div>
        <div class="header">
          <div class="title">My profile</div>
          <div class="tmblr" :class="{ 'tmblr-hide': !show_my_profile }">
            <div
              class="btn btn-tmblr"
              :class="show_my_profile ? 'btn-tmblr-l' : 'btn-tmblr-r'"
              @click="toogleShowMyProfile"
            ></div>
            <div class="txt">Hide</div>
          </div>
        </div>
        <div class="content" :class="{ 'blur': !show_my_profile }">
          <div class="col col-1">
            <div class="card card-big">
              <User class="img"/>
              <div class="data">
                <div class="ttl">Company full name</div>
                <div class="desc" v-if="!editMyProfileState">{{ myProfileForm.company_full_name }}</div>
                  <input name="company_full_name" class="form-control" v-if="editMyProfileState" type="text" v-model="myProfileForm.company_full_name" placeholder="">
                  <span v-show="myProfileFormErrors.company_full_name" class="invalid-feedback">{{ myProfileFormErrors.company_full_name }}</span>
              </div>
            </div>
            <div class="card card-country">
                <div class="data">
                  <div class="ttl">Your business activities</div>
                  <div v-if="!editMyProfileState" class="desc">{{ myProfileForm.business_activity_name  }}</div>
                    <multiselect
                      v-if="editMyProfileState"
                      :options="businessActivities"
                      v-model="profileBusinessActivity"
                      track-by="id"
                      label="name"
                      placeholder="Select business activity"
                      :max-height="250"
                    />
                </div>
              </div>
          </div>
          <div class="col col-2">
            <div class="subcol subcol-1">
              <div class="card card-login">
                <Lock class="img"/>
                <div class="data">
                  <div class="ttl">Profile login</div>
                  <div  class="desc">{{ myProfileForm.member_login }}</div>
                  <!-- <input class="form-control" type="text" v-model="myProfileForm.member_login" placeholder=""> -->
                  <span v-show="myProfileFormErrors.member_login" class="invalid-feedback">{{ myProfileFormErrors.member_login }}</span>
                </div>
              </div>
              <div class="card card-country">
                <Worldwide class="img"/>
                <div class="data">
                  <div class="ttl">Country of registration</div>
                  <div v-if="!editMyProfileState" class="desc">{{ myProfileForm.registration_country_name  }}</div>
                    <multiselect
                      v-if="editMyProfileState"
                      :options="countries"
                      v-model="profileRegCountry"
                      track-by="id"
                      label="name"
                      placeholder="Select country"
                      :max-height="250"
                    />
                </div>
              </div>
            </div>
            <div class="subcol subcol-2">
              <div class="card card-website">
                <BrowserWindow class="img"/>
                <div class="data">
                  <div class="ttl">Website</div>
                  <div v-if="!editMyProfileState" class="desc">{{ myProfileForm.website }}</div>
                  <input class="form-control" v-if="editMyProfileState" type="text" v-model="myProfileForm.website" placeholder="">
                </div>
              </div>
              <div class="card card-company_reg_number">
                <ShoppingList class="img"/>
                <div class="data">
                  <div class="ttl">Company registration number</div>
                  <div v-if="!editMyProfileState" class="desc">{{ myProfileForm.company_registration_number  }}</div>
                  <input class="form-control" v-if="editMyProfileState" type="text" v-model="myProfileForm.company_registration_number" placeholder="">
                </div>
              </div>
            </div>
            <div class="subcol subcol-3">
              <div class="card card-email">
                <Mail class="img"/>
                <div class="data">
                  <div class="ttl">E-mail</div>
                  <div v-if="!editMyProfileState" class="desc">{{ myProfileForm.email }}</div>
                  <input class="form-control" v-if="editMyProfileState" type="text" v-model="myProfileForm.email" placeholder="">
                  <span v-show="myProfileFormErrors.email" class="invalid-feedback">{{ myProfileFormErrors.email }}</span>
                </div>
              </div>
              <div class="card card-phone">
                <Telephone class="img"/>
                <div class="data">
                  <div class="ttl">Mobile phone</div>
                  <div v-if="!editMyProfileState" class="desc">{{myProfileForm.mobile_phone }}</div>
                  <input class="form-control" v-if="editMyProfileState" type="text" v-model="myProfileForm.mobile_phone" placeholder="">
                  <span v-show="myProfileFormErrors.mobile_phone" class="invalid-feedback">{{ myProfileFormErrors.mobile_phone }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="box-container">
      <div class="box box_b-info">
        <div v-if="!editBusinessSettingsState" @click="editBusinessSettings" class="btn btn-edit">
          <Pen class="img"/>
        </div>
        <div v-if="editBusinessSettingsState" class="btn btn-save" @click="saveBusinessSettings">
          <Save class="img"/>
        </div>
        <div class="header">
          <div class="title">Business information</div>
        </div>
        <div class="content">
          <div class="row">
            <div class="card">
              <Dollar class="img"/>
              <div class="data">
                <div class="ttl">Monthly turnover ($)</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.monthly_turnover }}</div>
                <input name="monthly_turnover" class="form-control" v-if="editBusinessSettingsState" type="text" v-model="businessSettingsForm.monthly_turnover" placeholder="">
                  <span v-show="businessSettingsErrors.monthly_turnover" class="invalid-feedback">{{ businessSettingsErrors.monthly_turnover }}</span>
              </div>
            </div>
            <div class="card">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">Geo of clients</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.clients_geo_name }}</div>
                <multiselect
                  v-if="editBusinessSettingsState"
                  :options="countriesPlus"
                  v-model="clientGeoSelected"
                  track-by="id"
                  label="name"
                  placeholder="Select country"
                  :max-height="250"
                />
              </div>
            </div>
            <div class="card">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">Address of Substance</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.company_country_origin_name }}</div>
                <multiselect
                  v-if="editBusinessSettingsState"
                  :options="countriesPlus"
                  v-model="companyCountryOriginSelected"
                  track-by="id"
                  label="name"
                  placeholder="Select country"
                  :max-height="250"
                />
              </div>
            </div>
          <!-- </div> -->
          <!-- <div class="row"> -->
            <div class="card card-min">
              <DocLicense class="img"/>
              <div class="data">
                <div class="ttl">License</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.worldwide_licence_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.worldwide_licence">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="card card-min">
              <DocLicense class="img"/>
              <div class="data">
                <div class="ttl">KYC</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.worldwide_kyc_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.worldwide_kyc">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="card">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">UBO residental</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.ubo_residential_name }}</div>
                <multiselect
                  v-if="editBusinessSettingsState"
                  :options="countriesPlus"
                  v-model="uboResidentialSelected"
                  track-by="id"
                  label="name"
                  placeholder="Select country"
                  :max-height="250"
                />
              </div>
            </div>
            <div class="card card-min">
              <BreifcaceTr class="img"/>
              <div class="data">
                <div class="ttl">Director nominal</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.ubo_nominal_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.ubo_nominal">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box box_comp-logo">
        <span class="dots dots-top"><Dots5/></span>
        <span class="dots dots-left"><Dots5/></span>
        <span class="dots dots-bottom"><Dots5/></span>
        <div class="header">
          <div class="title">Logo of Company</div>
        </div>
        <div class="logo" v-bind:style="{ 'background-image': 'url(' + logo + ')' }">
          <div class="btn btn_update" id="change-logo" @click="changeLogo"><Exchange /></div>
          <avatar-cropper
            @uploaded="onLogoUploaded"
            @completed="onLogoUploadCompleted"
            :upload-headers="logoUploadHeaders"
            trigger="#change-logo"
            :labels="{submit: 'Save', cancel: 'Cancel'}"
            :upload-url="updateLogoUrl" />
          <div class="btn btn_remove" @click="removeLogo"><Close /></div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState } from 'vuex';
import Pen from '@/components/img/Pen.vue'
import User from '@/components/img/User.vue'
import Lock from '@/components/img/Lock.vue'
import Worldwide from '@/components/img/Worldwide.vue'
import BrowserWindow from '@/components/img/BrowserWindow.vue'
import ShoppingList from '@/components/img/ShoppingList.vue'
import Mail from '@/components/img/Mail.vue'
import Telephone from '@/components/img/Telephone.vue'
import Dollar from '@/components/img/Dollar.vue'
import MapPin from '@/components/img/MapPin.vue'
import DocLicense from '@/components/img/DocLicense.vue'
import BreifcaceTr from '@/components/img/BreifcaceTr.vue'
import Dots5 from '@/components/img/Dots5.vue'
import Exchange from '@/components/img/Exchange.vue'
import Close from '@/components/img/Close.vue'
import Save from '@/components/img/Save.vue'
import AvatarCropper from 'vue-avatar-cropper'

export default {
  components: {
    Pen,
    User,
    Lock,
    Worldwide,
    BrowserWindow,
    ShoppingList,
    Mail,
    Telephone,
    Dollar,
    MapPin,
    DocLicense,
    BreifcaceTr,
    Dots5,
    Exchange,
    Close,
    Save,
    AvatarCropper
  },
  data: () => ({
    logo: '',
    updateLogoUrl: process.env.VUE_APP_BACKEND_API_URL + 'user/update-logo',
    logoUploadHeaders: {},
    show_my_profile: true,
    editMyProfileState: false,
    editBusinessSettingsState: false,
    kybRateComment: null,
    myProfileForm: {
      company_full_name: null,
      member_login: null,
      registration_country: null,
      registration_country_name: null,
      company_registration_number: null,
      email: null,
      mobile_phone: null,
    },
    businessSettingsForm: {
      clients_geo: null,
      clients_geo_name: '',
      company_country_origin: null,
      company_country_origin_name: '',
      monthly_turnover: '',
      ubo_nominal: false,
      ubo_nominal_text: '',
      ubo_residential: null,
      ubo_residential_name: '',
      user_id: null,
      worldwide_kyc: false,
      worldwide_kyc_text: '',
      worldwide_licence: false,
      worldwide_licence_text: ''
    },
    myProfileFormErrors: {
      company_full_name: null,
      member_login: null,
      registration_country: null,
      company_registration_number: null,
      email: null,
      mobile_phone: null,
    },
    businessSettingsErrors : {
      clients_geo: null,
      company_country_origin: null,
      monthly_turnover: '',
      ubo_nominal: false,
      ubo_residential: null,
      user_id: null,
      worldwide_kyc: false,
      worldwide_licence: false,
    },
    countries: [],
    businessActivities: [],
    profileBusinessActivity: {},
  }),
  computed: {
    ...mapState({
      rate: state => state.userKybRate,
    }),
    countriesPlus () {
      let countries = this.countries.slice()
      if (countries.length) countries.unshift({ code: null, id: null, name: 'Worldwide' })
      return countries
    },
    profileRegCountry: {
      get() {
        return this.myProfileForm.registration_country === null ? null
          : this.countries.find(el => +el.id === +this.myProfileForm.registration_country)
      },
      set(selected) { this.setSelectedCountryData('myProfileForm', 'registration_country', selected) }
    },
    clientGeoSelected: {
      get() { return this.getBusinessSettingsCountryObj('clients_geo') },
      set(selected) { this.setSelectedCountryData('businessSettingsForm', 'clients_geo', selected) }
    },
    companyCountryOriginSelected: {
      get() { return this.getBusinessSettingsCountryObj('company_country_origin') },
      set(selected) { this.setSelectedCountryData('businessSettingsForm', 'company_country_origin', selected) }
    },
    uboResidentialSelected: {
      get() { return this.getBusinessSettingsCountryObj('ubo_residential') },
      set(selected) { this.setSelectedCountryData('businessSettingsForm', 'ubo_residential', selected) }
    },
  },
  methods: {
    getUser () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'auth/me')
        .then(function (response) {
          that.logo = (response.data.logo) ? process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/origin/' +  response.data.logo : '/img/default-avatar.png';
          that.myProfileForm.company_full_name = response.data.company_full_name
          that.myProfileForm.member_login = response.data.member_login
          that.myProfileForm.registration_country = response.data.registration_country
          that.myProfileForm.registration_country_name = response.data.registration_country_name
          that.myProfileForm.business_activity_id = response.data.business_activity_id
          that.myProfileForm.business_activity_name = response.data.business_activity_name
          that.kybRateComment = response.data.kyb_rate_comment
          that.myProfileForm.website = response.data.website
          that.myProfileForm.company_registration_number = response.data.company_registration_number
          that.myProfileForm.email = response.data.email
          that.myProfileForm.mobile_phone = response.data.mobile_phone
          that.show_my_profile = (response.data.hide) ? !response.data.hide : true
          that.businessSettingsForm.clients_geo = response.data.business_settings.clients_geo
          that.businessSettingsForm.clients_geo_name = response.data.business_settings.clients_geo_name
          that.businessSettingsForm.company_country_origin = response.data.business_settings.company_country_origin
          that.businessSettingsForm.company_country_origin_name = response.data.business_settings.company_country_origin_name
          that.businessSettingsForm.monthly_turnover = response.data.business_settings.monthly_turnover
          that.businessSettingsForm.ubo_nominal = response.data.business_settings.ubo_nominal
          that.businessSettingsForm.ubo_nominal_text = response.data.business_settings.ubo_nominal_text
          that.businessSettingsForm.ubo_residential = response.data.business_settings.ubo_residential
          that.businessSettingsForm.ubo_residential_name = response.data.business_settings.ubo_residential_name
          that.businessSettingsForm.user_id = response.data.business_settings.user_id
          that.businessSettingsForm.worldwide_kyc = response.data.business_settings.worldwide_kyc
          that.businessSettingsForm.worldwide_kyc_text = response.data.business_settings.worldwide_kyc_text
          that.businessSettingsForm.worldwide_licence = response.data.business_settings.worldwide_licence
          that.businessSettingsForm.worldwide_licence_text = response.data.business_settings.worldwide_licence_text
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    toogleShowMyProfile () {
      this.show_my_profile = !this.show_my_profile
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/toogle-show-my-profile', {
        hide: !this.show_my_profile
      })
        .then(function (response) {
          that.$store.dispatch('setUser')
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    editMyProfile () {
      this.editMyProfileState = true
    },
    editBusinessSettings () {
      this.editBusinessSettingsState = true
    },
    clearMyProfileFormErrors () {
      for (let key in this.myProfileFormErrors) {
        this.myProfileFormErrors[key] = null
      }
    },
    saveMyProfile () {
      const that = this
      if (this.profileBusinessActivity && this.profileBusinessActivity.id) {
        this.myProfileForm.business_activity_id = this.profileBusinessActivity.id
      }  
      this.clearMyProfileFormErrors()
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/update', this.myProfileForm)
        .then(function (response) {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'company_full_name' && response.data.errors[key][0]) {
                that.myProfileFormErrors.company_full_name = response.data.errors[key][0]
              } else if (key === 'email' && response.data.errors[key][0]) {
                that.myProfileFormErrors.email = response.data.errors[key][0]
              } else if (key === 'member_login' && response.data.errors[key][0]) {
                that.myProfileFormErrors.member_login = response.data.errors[key][0]
              } else if (key === 'mobile_phone' && response.data.errors[key][0]) {
                that.myProfileFormErrors.mobile_phone = response.data.errors[key][0]
              }
            }
            return
          }
          that.$store.dispatch('setUser')
          that.$store.dispatch('getUserKybRate')
          that.editMyProfileState = false
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    clearBusinessSettingsErrors () {
      for (let key in this.businessSettingsErrors) {
        this.businessSettingsErrors[key] = null
      }
    },
    saveBusinessSettings () {
      this.clearBusinessSettingsErrors()
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'business-settings/update', this.businessSettingsForm)
        .then((response) => {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'monthly_turnover' && response.data.errors[key][0]) {
                that.businessSettingsErrors.monthly_turnover = response.data.errors[key][0]
              }
            }
            return
          }
          that.businessSettingsForm.ubo_residential_name = response.data.data.ubo_residential_name
          that.businessSettingsForm.clients_geo_name = response.data.data.clients_geo_name
          that.businessSettingsForm.company_country_origin_name = response.data.data.company_country_origin_name

          that.businessSettingsForm.ubo_nominal = response.data.data.ubo_nominal
          that.businessSettingsForm.ubo_nominal_text = response.data.data.ubo_nominal_text
          that.businessSettingsForm.worldwide_kyc = response.data.data.worldwide_kyc
          that.businessSettingsForm.worldwide_kyc_text = response.data.data.worldwide_kyc_text
          that.businessSettingsForm.worldwide_licence = response.data.data.worldwide_licence
          that.businessSettingsForm.worldwide_licence_text = response.data.data.worldwide_licence_text
          that.editBusinessSettingsState = false
          that.$store.dispatch('getUserKybRate')
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getCountries () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/countries')
        .then(function (response) {
          that.countries = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getBusinessActivities () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'data/business-activities')
        .then(function (response) {
          that.businessActivities = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    // setCountryName (countryId) {
    //   let countryIsset = false
    //   if (this.countries) {
    //     this.countries.forEach(country => {
    //       if (country.id === countryId) {
    //         this.myProfileForm.registration_country_name = country.name
    //         countryIsset = true
    //       }
    //     })
    //   }
    //   if (!countryIsset) {
    //     this.myProfileForm.registration_country_name = ''
    //   }
    // },
    // setBusinessFormCountryName (countryId, fieldName) {
    //   let countryIsset = false
    //   if (this.countries) {
    //     this.countries.forEach(country => {
    //       if (country.id === countryId) {
    //         this.businessSettingsForm['fieldName_' + fieldName] = country.name
    //         countryIsset = true
    //       }
    //     })
    //   }
    //   if (!countryIsset) {
    //     this.businessSettingsForm['fieldName_' + fieldName] = 'Worldwide'
    //   }
    // },
    removeLogo () {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/remove-logo')
        .then((response) => {
          if (response.data.status == 'success') {
            that.logo = '/img/default-avatar.png'
            that.$store.dispatch('setUser')
            that.$store.dispatch('getUserKybRate')
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    changeLogo () {
      console.log('changeLogo111')
    },
    onLogoUploaded (response) {
      this.logo = process.env.VUE_APP_BACKEND_URL + response.origin_url
    },
    onLogoUploadCompleted () {
      this.$store.dispatch('setUser')
      this.$store.dispatch('getUserKybRate')
    },
    setSelectedCountryData (form, prop, selected) {
      this[form][prop] = selected.id
      this[form][`${prop}_name`] = selected.name
    },
    getBusinessSettingsCountryObj (prop) {
      return this.businessSettingsForm[`${prop}_name`] === null ? null
          : this.countriesPlus.find(el => el.id === this.businessSettingsForm[prop])
    }
  },
  created () {
    this.getUser()
    this.getCountries()
    this.getBusinessActivities()
    this.logoUploadHeaders = {
      Authorization: 'Bearer ' + this.$store.state.token
    }
  },
   watch: {
    'profileBusinessActivity.name': function(profileBusinessActivityName) {
      if (profileBusinessActivityName) {
        this.myProfileForm.business_activity_name = profileBusinessActivityName
      }
    }
  }
  // watch: {
  //   'myProfileForm.registration_country': function (countryId) {
  //     this.setCountryName(countryId)
  //   },
  //   countries: function (countries) {
  //     if (countries) {
  //       if (this.myProfileForm.registration_country) {
  //         this.setCountryName(this.myProfileForm.registration_country)
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.page-personal {
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_rate {
      position: relative;
      height: 7rem;
      height: 11vh;
      margin-top: 6rem;
      margin-top: 7vh;
      margin-bottom: 4rem;
      margin-bottom: 6vh;

      .box-inside {
        width: 95%;
        height: 11rem;
        height: calc(100% + 6vh);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--gr-2);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        display: flex;
        z-index: 9;

        .content {
          flex-grow: 1;

          .scale-box {
            width: 100%;
          }
        }

        .img {
          height: 14rem;
          height: calc(100% + 6vh);
          position: relative;
          top: 100%;
          transform: translateY(-96%);
          margin: auto 3rem;
          margin: auto 3vw;
          filter: sepia(100%) hue-rotate(180deg) saturate(250%);
        }
      }
    }

    &_my-profile {
      position: relative;
      width: 100%;
      padding-right: 4.25rem;
      background-color: #000000;

      .header {
        display: flex;

        .title {
          flex-grow: 1;
        }

        .tmblr {
          display: flex;
          align-items: center;
          padding: 0 .25rem;

          .txt {
            color: rgba(255, 255, 255, 0.5);
          }

          .btn {
            &-tmblr {
              position: relative;
              width: 1.5rem;
              height: .5rem;
              background-color: rgba(255, 255, 255, 0.12);
              border-radius: 2rem;
              margin: auto 1rem;

              &-l::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }

              &-r::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }

          &-hide {
            .txt {
              color: var(--c_primary);
            }

            .btn-tmblr-r::before {
              background-color:  var(--c_primary);
            }
          }
        }
      }

      .content {
        padding: 0 0 1rem 0;
        padding: 0 0 1.5vh 0;
        display: flex;

        &.blur {
          filter: blur(7px);
        }

        .card {
          &-big {
            width: 100%;
            height: 11.5rem;
            display: flex;
            align-items: flex-end;
            // background-image: url('/img/two_line.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 1.25rem 1.75rem;

            svg.img {
              width: 3.5rem;
              height: 4rem;
              margin-right: 1rem;
            }

            .data {
              flex-grow: 1;

              .ttl {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
              }

              .desc {
                font-size: 1.25rem;
                font-weight: 600;
              }
            }
          }
        }

        .col {
          &-1 {
            width: 27rem;
            width: 21vw;
            padding-right: .5rem;

            @media (max-width: 1440px) {
              width: 50vw;
            }
          }

          &-2 {
            flex-grow: 1;
            padding-left: .5rem;
            padding-right: .5rem;
            display: flex;
            flex-wrap: wrap;

            .subcol {
              padding: 0 .5rem;

              &-1 {
                width: 30%;

                @media (max-width: 1440px) {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;

                  .card {
                    width: 48%;
                  }
                }
              }

              &-2,
              &-3 {
                width: 35%;

                @media (max-width: 1440px) {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;

                  .card {
                    width: 48%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      background-color: #000000;
      margin-right: 2rem;

      .header {
        padding-right: 4.25rem;

        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .content {
        .row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .card {
            background: rgb(92 85 199 / 20%);
            width: 30%;

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(7) {
              width: 20%;
            }

            @media (max-width: 1440px) {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(6),
              &:nth-child(7) {
                width: 48.5%;
              }

              &:nth-child(4),
              &:nth-child(5) {
                width: 22.75%;
              }
            }

            // &-min {
              // width: 20%;
            // }
          }
        }
      }
    }

    &_comp-logo {
      width: 23rem;
      position: relative;
      background-color: #272E35;
      overflow: hidden;
      padding-bottom: 1rem;

      .dots {
        position: absolute;

        &-top {
          top: -5px;
          right: 7px;
          width: 2rem;
          opacity: .5;
        }

        &-left {
          top: 6.5rem;
          left: 28px;
          width: 1.5rem;
          opacity: .5;
        }

        &-bottom {
          bottom: -12px;
          right: 1.75rem;
          width: 2.5rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .header {
        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .logo {
        flex-grow: 1;
        width: 14rem;
        height: 14rem;
        margin: .25rem auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
        z-index: 1;

        .btn {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &_update {
            background-color: var(--c_primary);

            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          &_remove {
            background-color: #B03636;

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
    filter: sepia(80%) hue-rotate(190deg) saturate(100%);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #1b3578;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #1b3578;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg.img {
      width: 1.5rem;
      height: 1.5rem;

      path {
        fill: var(--c_primary);
      }
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: .5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .5rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

::v-deep .multiselect {
  padding: 0;

  &__tags {
    min-height: 38px;
    background: #1C2126;
    border: 1px solid #2F363D;
  }

  &__input,
  &__single {
    background: #1C2126;
    color: #ffffff;

    &::placeholder {
      color: #ffffff;
    }
  }

  &__content-wrapper {
    background: #1C2126;
    color: #ffffff;
    border: 1px solid #2F363D;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.kyb-rate-comment {
  margin-top: 10px;
}
</style>

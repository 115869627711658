<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        <span v-if="options.actionType == 'create'">Add Legal NET Products</span>
        <span v-else>Edit Legal NET Products</span>
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group half">
          <label>Name of Legal Co.</label>
          <input v-model="form.legal_name" type="text" class="form-control" placeholder="Your Company name">
          <span v-show="formErrors.legal_name" class="invalid-feedback">{{ formErrors.legal_name }}</span>
        </div>
        <div class="form-group half">
          <label>Type of Services</label>
          <input v-model="form.services_type" type="text" class="form-control" placeholder="Ex.: Incorporation company">
          <span v-show="formErrors.services_type" class="invalid-feedback">{{ formErrors.services_type }}</span>
        </div>
        <div class="form-group half">
          <label>Price</label>
          <div class="inpt-block">
            <input v-model="form.price" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.price" class="invalid-feedback">{{ formErrors.price }}</span>
        </div>
        <div class="form-group half">
          <label>Service time</label>
          <div class="inpt-block">
            <input v-model="form.service_time" type="text" class="form-control" placeholder="3">
            <div class="desc">works day</div>
          </div>
          <span v-show="formErrors.service_time" class="invalid-feedback">{{ formErrors.service_time }}</span>
        </div>
        <div class="form-group">
          <label>Country</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.country_id">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>
          <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
        </div>
        <div class="form-group">
          <label>Description of Benefit</label>
          <div class="inpt-block">
            <textarea style="width: 100%" v-model="form.benefit_description" type="text" class="form-control" placeholder="Description of Benefit" rows="3"></textarea>
          </div>
          <span v-show="formErrors.benefit_description" class="invalid-feedback">{{ formErrors.benefit_description }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created () {
    this.getCountries()
    if (this.options.actionType == 'edit' ) {
      this.getLegalAtlas(this.options.id)
    }
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      form: {
        legal_name: '',
        services_type: '',
        price: '',
        service_time: '',
        benefit_description: '',
        country_id: '',
      },
      formErrors: {
        legal_name: '',
        services_type: '',
        price: '',
        service_time: '',
        benefit_description: '',
        country_id: ''
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    getCountries() {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch((error) => console.log(error))
    },
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getLegalAtlas () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'provider/legal-atlas/' + this.options.id, this.form)
      .then(function (response) { 
        that.form = response.data
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    async submit() {
      const that = this
      this.clearErrors()
      this.form.provider_id = this.user.id
      axios.post(this.options.url, this.form)
      .then(function (response) { 
        if (response.data.errors) {
          const errors = response.data.errors
          for (let key in errors) {
            that.formErrors[key] = errors[key][0]
          }
        }
        if (response.data.success) {
          that.close()
          that.$noty.success(that.options.successfullMessage)
          that.$store.dispatch('editItemFromTable', {
            id: that.options.id,
            table: that.options.table,
            actionType: that.options.actionType
          })
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  @media (max-height: 1200px) {
    &__body .form {
     overflow-y: auto;
     height: 400px;
    }   
  }

  @media (max-height: 1080px) {
    &__body .form {
     overflow-y: auto;
     height: 400px;
    }   
  }

  @media (max-height: 1024px) {
    &__body .form {
     overflow-y: auto;
     height: 400px;
    }   
  }

  @media (max-height: 960px) {
    &__body .form {
     overflow-y: auto;
     height: 400px;
    }   
  }

  @media (max-height: 768px) {
    &__body .form {
     overflow-y: auto;
     height: 300px;
    }   
  } 
}

.form-control {
  color: #000000;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Personal from '../views/Personal.vue'
import Notifications from '../views/Notifications.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Personal',
    component: Personal,
    meta: {
      requiresAuth: true,
      desc: 'Business Profile'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login-code',
    name: 'LoginCode',
    component: () => import('../views/LoginCode.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/passwords/ForgotPassword.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'NewPassword',
    component: () => import('../views/passwords/NewPassword.vue')
  },
  {
    path: '/compliance',
    name: 'Compliance',
    component: () => import('../views/Compliance.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Compliance'
    }
  },
  {
    path: '/legal_atlas',
    name: 'Legal Atlas',
    component: () => import('../views/LegalAtlas.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Legal Net'
    }
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/Balance.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Balance'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Settings'
    }
  },
  {
    path: '/bos_business',
    name: 'Buy & Sell NET License',
    component: () => import('../views/BuyOrSellBusiness.vue'),
    meta: {
      requiresAuth: true,
      desc: 'Buy & Sell NET License'
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('../views/Accounts'),
    meta: {
      requiresAuth: true,
      desc: 'Accounts'
    }
  },
  {
    path: '/payment-atlas',
    name: 'Payment Atlas',
    component: () => import('../views/PaymentAtlas'),
    meta: {
      requiresAuth: true,
      desc: 'Payment Atlas'
    }
  },
  {
    path: '/software_net',
    name: 'SoftwareNet',
    component: () => import('../views/SoftwareNet'),
    meta: {
      requiresAuth: true,
      headerName: 'Software Net'
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue'),
    meta: {
      requiresAuth: true,
      isChat: true
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../views/Partners.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
        requiresAuth: true,
        headerName: 'Notifications'
      }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { mixins } from '../../mixins'

export default ({
  namespaced: true,
  state: () => ({
    bankAcc: { 
      objName: 'bankAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name', sort: 'type_of_license' },
        { ttl: 'Type of Account', sort: 'account_type' },
        { ttl: 'Open/Integration fee', sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', sort: 'onboarding_time' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
    merchantAcc: {
      objName: 'merchantAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name', sort: 'type_of_license' },
        { ttl: 'Type of Zone', sort: 'account_type' },
        { ttl: 'Open/Integration fee', sort: 'open_integration_fee' },
        { ttl: 'Incoming fee', sort: 'incoming_fee' },
        { ttl: 'Outgoing fee', sort: 'outgoing_fee' },
        { ttl: 'Onboarding time', sort: 'onboarding_time' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
  }),
  actions: {
    getBankOffers ({ state, commit }, payload = { page: 1 }) {
      let page = 1
      if (payload && typeof payload == 'number') {
        page = payload
      } else {
        page = payload.page
      }
      const url = {
        base: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/bank-accounts',
        page: `?page=${page}`,
        sort: `&sort=${state.bankAcc.sort.name}`,
        order: `&order=${state.bankAcc.sort.order}`,
        other: `&provider=true`,
      }
      axios.get(url.base + url.page + url.sort + url.order + url.other)
        .then(({ data }) => {
          commit('setBankAcc', { data: data })
        })
        .catch(err => console.dir(err))
    },
    getMerchantOffers ({ state, commit }, payload = { page: 1 }) {
      let page = 1
      if (payload && typeof payload == 'number') {
        page = payload
      } else {
        page = payload.page
      }
      const url = {
        base: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/merchant-accounts',
        page: `?page=${page}`,
        sort: `&sort=${state.bankAcc.sort.name}`,
        order: `&order=${state.bankAcc.sort.order}`,
        other: `&provider=true`,
      }
      axios.get(url.base + url.page + url.sort + url.order + url.other)
        .then(({ data }) => {
          commit('setMerchantAcc', { data: data })
        })
        .catch(err => console.dir(err))
    },
  },
  mutations: {
    setBankAcc (state, payload) {
      state.bankAcc.paginationData = payload.data
      state.bankAcc.body = []
      payload.data.data.forEach(el => {
        const price = el.oif_min_max && el.open_integration_fee_max
          ? mixins.methods.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ mixins.methods.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
          : mixins.methods.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
        const finName = !el.provider_id && !el.is_legal_company
          ? el.financial_institution_name
          : {
              type: 'info',
              html: el.financial_institution_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        state.bankAcc.body.push([
          el.serial_number,
          finName,
          el.account_type,
          price,
          el.incoming_fee,
          el.outgoing_fee,
          el.onboarding_time_text,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
      if (state.bankAcc.sort.name) state.bankAcc.sort.loading = false
    },
    setMerchantAcc (state, payload) {
      state.merchantAcc.paginationData = payload.data
      state.merchantAcc.body = []
      payload.data.data.forEach(el => {
        const price = el.oif_min_max && el.open_integration_fee_max
          ? mixins.methods.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ mixins.methods.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
          : mixins.methods.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
        const finName = !el.provider_id && !el.is_legal_company
          ? el.financial_institution_name
          : {
              type: 'info',
              html: el.financial_institution_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        state.merchantAcc.body.push([
          el.serial_number,
          finName,
          el.account_type,
          price,
          el.incoming_fee,
          el.outgoing_fee,
          el.onboarding_time_text,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
      if (state.merchantAcc.sort.name) state.merchantAcc.sort.loading = false
    },
  }
})
export default ({
  namespaced: true,
  state: () => ({
    addServicesTableData: {
      colsWidth: ['5%', '18%', '18%', '13%', '12%', '12%', '12%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Service Name' },
        { ttl: 'Country' },
        { ttl: 'Service Type' },
        { ttl: 'Price' },
        { ttl: 'Service Time' },
        { ttl: 'Description' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null
    },
  }),
  actions: {
    async getAddServices ({ commit }, payload = { page: 1 }) {
      await axios.get(process.env.VUE_APP_BACKEND_API_URL +'provider/software-add-services?page='+ payload.page +'&provider=true')
        .then(({ data }) => {
          commit('setAddServicesTableData', { data: data });
        })
    },
  },
  mutations: {
    setAddServicesTableData (state, payload) {
      state.addServicesTableData.paginationData = payload.data;
      state.addServicesTableData.body = []
      payload.data.data.forEach(el => {
        const name = !el.provider_id && !el.is_legal_company
          ? el.service_name
          : {
              type: 'info',
              html: el.service_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }
        state.addServicesTableData.body.push([
          el.id,
          name,
          el.country ? el.country.name : '',
          el.service_type,
          el.price,
          el.service_time,
          el.description,
          { type: 'edit-delete-actions', id: el.id }
        ])
      });
    },
  }
})

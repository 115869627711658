<template>
  <div id="header">
    <div class="left">
      <div class="title">
        <ArrowFlat class="img"/>
        <div class="desc">{{ $route.meta.desc }}</div>
      </div>
    </div>
    <div class="right">
      <div class="search">
        <input type="text" name="search" id="search" placeholder="Search here">
        <Search class="img"/>
      </div>
      <router-link :to="{ name: 'Settings' }" class="btn btn-settings">
        <Settings class="img"/>
      </router-link>
      <Notifications />
      <Messages />
      <div
        class="profile" :class="{ 'open': dropdown }"
        @click="dropdown = !dropdown" v-click-outside="dropdownClose"
      >
        <div class="ava" :style="{ 'background-image': 'url(' + userLogo + ')' }"></div>
        <div class="name">{{ user ? user.company_full_name : '' }}</div>
        <ArrowFlat class="arrow"/>
        <div class="list">
          <a @click="logout" href="#" class="item">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowFlat from '@/components/img/ArrowFlat.vue'
import Search from '@/components/img/Search.vue'
import Settings from '@/components/img/Settings.vue'
import Bell from '@/components/img/Bell.vue'
import Chat from '@/components/img/Chat.vue'
import Notifications from './../components/common/notifications/notifications.vue'
import Messages from './../components/common/messages/messages.vue'

export default {
  components: {
    ArrowFlat,
    Search,
    Settings,
    Bell,
    Chat,
    Notifications,
    Messages
  },
  data() {
    return {
      dropdown: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    userLogo () {
      return this.user && this.user.logo
        ? process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/origin/' +  this.user.logo
        : '/img/default-avatar.png'
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      window.location.href = process.env.VUE_APP_CABINET_URL
    },
    dropdownClose() {
      this.dropdown = false
    },
    openChat () {
      Tawk_API.maximize()
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  --left_offset: 2rem;

  position: relative;
  left: -2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + var(--left_offset));
  max-width: calc(100% + var(--left_offset));
  min-height: var(--header_height);
  padding: .5rem 2.5rem;
  padding-left: calc(2.5rem + var(--left_offset));
  background-color: #24292E;

  @media (max-width: 1366px) {
    padding: .5rem 1rem;
    padding-left: calc(1rem + var(--left_offset));
  }

  .btn, a.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: .75rem;
    margin: auto .5rem;
    background-color: #2F363D;

    .img {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_mark {
      ::v-deep svg.img path {
        fill: var(--c_primary);
      }
    }

    &_mark::before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 1rem;
      height: 1rem;
      background-color:var(--c_primary);
      border-radius: 50%;
    }

    &-settings {
      background-color: var(--c_primary);
      margin-right: 1.5rem;

      @media (max-width: 1024px) {
        margin-right: .5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 1rem;
        height: 1rem;
        margin: .5rem;
      }

      .desc {
        font-size: 1.75rem;
        font-weight: 600;
        margin: .5rem 1rem;
        padding-left: .5rem;
        white-space: nowrap;

        @media (max-width: 1366px) {
          margin: .5rem 0;
          font-size: 1.5rem;
        }

        @media (max-width: 1024px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      flex-grow: 1;
      position: relative;
      height: 54px;
      // width: 26rem;
      // width: 25vw;
      max-width: 26rem;
      margin: auto 1rem;

      input#search {
        width: 100%;
        height: 100%;
        color: #ffffff;
        background-color: #1C2126;
        padding: .5rem 3.25rem .5rem 1.25rem;
        margin: auto .5rem;
        border: 1px solid #2F363D;
        border-radius: .75rem;
        box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
        outline: none;
        -webkit-appearance: none;

        &::placeholder {
          color: #B9B9B9;
        }
      }

      .img {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .profile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #2F363D;
      border-radius: .75rem;
      margin-left: 1.75rem;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: .5rem;
      }

      .ava {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: .75rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .name {
        margin: 0 .25rem 0 .75rem;
        padding: .25rem .5rem;
      }

      .arrow {
        width: .75rem;
        height: .75rem;
        margin: 0 1rem 0 .25rem;
        transform: rotate(90deg);
      }

      .list {
        display: none;
        position: absolute;
        top: 100%;
        right: -1px;
        min-width: 100%;
        min-width: calc(100% + 2px);
        text-align: right;
        border: 1px solid #2F363D;
        border-top: none;
        border-bottom-left-radius: .75rem;
        border-bottom-right-radius: .75rem;
        background-color: #1c2126;
        z-index: 2;

        .item {
          padding: 1rem;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &:last-child {
            border-bottom-left-radius: .75rem;
            border-bottom-right-radius: .75rem;
          }

          &:hover {
            background-color: #2f363d;
          }
        }
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #1c2126;

        .ava {
          border-bottom-left-radius: 0;
        }

        .arrow {
          transform: rotate(-90deg);
        }

        .list {
          display: block;
        }
      }
    }
  }
}
</style>

export default {
  methods: {
  	textNotification (notification) {
  		    let authorName = notification.author.full_name ? notification.author.full_name : notification.author.member_login
	        const notificationData = notification.notification.data

	      	if (notificationData.type === 'provider-apply-legal-service') {
		        const message = (notificationData.legalService.legal_name) ? "User " + authorName + " applied your legal service " + notificationData.legalService.legal_name + "." : "User " + notification.notification.data.user_company_full_name + " applied your legal service."
		        return message 
	      	} 

	      	if (notificationData.type === 'provider-apply-software-net') {
	          const message = (notificationData.softwareNet.service_name) ? "User " + authorName + " applied your software service " + notificationData.softwareNet.service_name + "." : "User " + notification.notification.data.user_company_full_name + " applied your software service."
	          return message     
	        }  

	        if (notificationData.type === 'provider-apply-offer') {
	          const message = (notificationData.offer.financial_institution_name) ? "User " + authorName + " applied your offer " + notificationData.offer.financial_institution_name + "." : "User " + notification.notification.data.user_company_full_name + " applied your offer."
	          return message     
	        }

	        if (notification.notification.data.type === 'chat-select-offer') {
	          return authorName + ' chat select ' + notificationData.offer_type + ' in chat with ' + notificationData.participant_names
	        }    

	        if (notification.notification.data.type === 'chat-change-offer-status') { 
	          return authorName + ' changed offer status in chat with ' + notification.notification.data.participant_names
	        }

	        if (notification.notification.data.type === 'create-service-chat') {
	          return authorName + ' applied service and created chat'
	        }

	        if (notification.notification.data.type === 'create-offer-chat') {
	          return authorName + ' applied offer and created chat'
	        }    

	      	return ''
      },
  }
}
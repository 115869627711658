<template>
  <div v-if="modal.template" class="modal">
    <div class="modal__back" @click.self="closeModal" />
    <component @close="closeModal" :is="`${modal.template}-modal`" :options="modal.options" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ContactFormModal from './ContactFormModal';
import BosContactFormModal from './BosContactFormModal';
import ChangePasswordModal from './ChangePasswordModal';
import SoftwareOfferDescriptionModal from './SoftwareOfferDescriptionModal';
import LegalAtlasModal from './LegalAtlasModal';
import DeleteModal from './DeleteModal';
import OfferModal from './OfferModal';
import PaymentAtlasModal from './PaymentAtlasModal';
import CreateSoftwareAddServiceModal from './CreateSoftwareAddServiceModal';
import SelectChatParticipantsModal from './SelectChatParticipantsModal';
import ChatSettingsModal from './ChatSettingsModal';
import SelectChatOfferUserModal from './SelectChatOfferUserModal';
import ChatOfferModal from './ChatOfferModal';
// import ProductDeletionConfirmModal from './ProductDeletionConfirmModal';
// import SplitBoosterConfirmModal from './SplitBoosterConfirmModal';

export default {
  components: {
    ContactFormModal,
    BosContactFormModal,
    ChangePasswordModal,
    SoftwareOfferDescriptionModal,
    LegalAtlasModal,
    DeleteModal,
    OfferModal,
    PaymentAtlasModal,
    CreateSoftwareAddServiceModal,
    SelectChatParticipantsModal,
    ChatSettingsModal,
    SelectChatOfferUserModal,
    ChatOfferModal,
  //   ProductDeletionConfirmModal,
  //   SplitBoosterConfirmModal,
  },
  computed: {
    ...mapState({
      modal: state => state.modal,
    }),
  },
  methods: {
    ...mapMutations({
      clearModal: 'closeModal',
    }),
    closeModal() {
      this.clearModal()
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;

  &__back {
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
  }

  &__wrapper {
    width: 95%;
    max-width: 65rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9991;
    color: var(--c_black);
    background-color: #ffffff;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, -50%);

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      background-color: #B03636;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 45%;
        height: 45%;
      }
    }
  }

  &__header {
    // min-height: calc(2.75rem + (2rem * 2));
    padding: 2.5rem .75rem;

    .ttl {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 1.25rem;
      font-weight: 600;
      // padding-bottom: 1rem;
      // margin: 0 calc(2.75rem + (2rem * 2));
      padding: 0 2.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 14px;
        left: 0;
        width: 1.75rem;
        height: 1px;
        background-color: var(--c_black);
      }

      // img {
      //   width: 2.25rem;
      //   height: 2.25rem;
      //   margin-right: 1.25rem;
      // }
    }

    .sbttl {
      font-style: italic;
      font-size: 1.25rem;
      text-align: center;
      padding: 0 1rem;

    }
  }

  &__body {
    height: 100%;
    flex-grow: 1;
    padding: 0 3.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      &-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;

        &.half {
          width: 48%;
        }

        > label,
        > input,
        > span,
        > .inpt-block {
          width: 100%;
        }

        label {
          padding-bottom: .25rem;
        }

        .form-control {
          background-color: #c8d9ef;
          padding: .75rem .75rem .75rem 1.25rem;
          margin: auto 0rem;
          border: 1px solid var(--simple_green);
          border-radius: .75rem;
          box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
          outline: none;
        }

        .inpt-block {
          display: flex;

          input {
            width: 100%;
          }

          .currency-symbol {
            width: 2rem;
            margin: auto .25rem auto .5rem;
            font-size: 1.75rem;
          }

          .desc {
            width: 2.5rem;
            margin: auto .25rem auto .5rem;
            font-size: 12px;
          }
        }

        .invalid-feedback {
          margin-top: .25rem;
          font-size: 14px;
          color: #dc3545;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      color: #ffffff;
      font-size: 18px;
      padding: 1rem 3rem;
      margin: 0.25rem 1rem;
      background: var(--gr-2);
      box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
      border-radius: 6px;
    }
  }
}
</style>
